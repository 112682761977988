import React, { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo, IIndex, IIndexColumn, IGizmo } from "flowy-3-core";
import {
  SearchOutlined,
  FilterOutlined,
  TableOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import downloadBatch from "./downloadBatch";
import downloadPdf from "./downloadPdf";
import Search from "./Search";
import ColumnSearch from "./ColumnSearch";
import * as S from "./Index.styles";
import { Button, Divider, TablePaginationConfig, Tooltip } from "antd";
import { Table } from "../../daq/AnnexForm/AnnexForm.styles";
import ColumnHeaderStatus from "./ColumnHeaderStatus";
import PrivacyModal from "./PrivacyModal";
import MapIndex from "./MapIndex";
import Map from "./Map";

type IndexProps = {
  gizmo: Gizmo;
};

const Index: FC<IndexProps> = ({ gizmo }) => {
  const { features, config, binder } = useGizmo({ gizmo });
  const [label, setLabel] = useState<string>("");
  const [index, setIndex] = useState<IIndex | undefined>();
  const [columns, setColumns] = useState<IIndexColumn[] | undefined>();
  const [rows, setRows] = useState<any[] | undefined>();
  const [pagination, setPagination] = useState<any | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [exportDisabled, setExportDisabled] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [indexType, setIndexType] = useState<string>("table");

  useEffect(() => {
    if (binder) {
      setLoading(true);
      const index: IIndex | undefined = binder.getIndex();
      if (index) {
        if (index.label) {
          setLabel(index.label);
        } else {
          setLabel(features.label);
        }
        setColumns(index.columns);
        setRows(index.rows);
        setPagination(index.pagination);
        setIndex(index);
      }
      setLoading(false);

      binder.index.subscribe((index: IIndex) => {
        if (index) {
          setColumns(index.columns);
          setRows(index.rows);
          setPagination(index.pagination);
          setIndex(index);
        }
        setLoading(false);
      });
    }
  }, [binder]);

  const handleCellClick = async ({
    column,
    row,
    action,
    operation,
  }: IHandleCellClick) => {
    if (action === "pdf.download") {
      await downloadPdf({
        operation: action,
        gizmo,
        row,
        column,
      });
    } else if (operation) {
      const response = await binder.cellInteraction({ column, row, operation });
      if (operation?.type === "register.pdf" && response?.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "export.pdf";
        link.click();
      }
    } else {
      await binder.cellInteraction({ column, row });
    }
  };

  const handlePageChange = async (pageData: TablePaginationConfig) => {
    setLoading(true);
    await binder.pageChange({
      page: pageData.current,
      pageSize: pageData.pageSize,
    });
    // const index: IIndex | undefined = binder.getIndex();

    // if (index) {
    //   setColumns(index.columns);
    //   setRows(index.rows);
    //   setPagination(index.pagination);
    // }
    // setLoading(false);
  };

  /**
   * Callback for search results coming from the server
   *
   * @param {IIndex} index search result
   */
  const handleSearchResult = (index: IIndex) => {
    // TODO: is this necessary? Or with the listener is enough?
    setColumns(index.columns);
    setRows(index.rows);
    setPagination(index.pagination);
  };

  const handleCreateButtonClick = async () => {
    let displayModal = false;
    const index = binder.getIndex();
    if (index?.rootGizmoConfigs && index?.rootGizmoConfigs.length > 0) {
      const rootGizmoConfig: IGizmo = index.rootGizmoConfigs[0];

      if (rootGizmoConfig.ops?.form?.privacyPolicy?.onCreate) {
        displayModal = true;
      }
    }

    if (displayModal) {
      setModalOpen(true);
    } else {
      await binder.dispatchOperation({
        operation: "navigation.link",
        params: {
          path: `/rg?rgfid=${gizmo.getParams().fid}`,
        },
      });
    }
  };

  const handleExportButtonClick = async () => {
    setExportDisabled(true);
    await downloadBatch({ gizmo });
    setExportDisabled(false);
  };

  /**
   * Callback for column search component
   *
   * @param {string[]} selectedKeys values from the search input
   * @param {IIndexColumn} column column to search
   */
  const handleColumnSearch = async (
    selectedKeys: string[],
    column: IIndexColumn
  ) => {
    setLoading(true);
    await binder.columnSearch({
      selectedKeys,
      column,
    });
  };

  const renderColumn = (column: IIndexColumn) => {
    const title =
      column.label.length > 20 ? (
        <Tooltip title={column.label}>
          <S.HeaderTitle>{column.label}</S.HeaderTitle>
        </Tooltip>
      ) : (
        <span>{column.label}</span>
      );
    const col: any = {
      title,
      key: column.fid,
    };

    if (column.gizmo?.ops?.indexColumn?.search) {
      col.filterDropdown = (props: any) => {
        return (
          <ColumnSearch
            {...props}
            column={column}
            gizmo={gizmo}
            onColumnSearch={handleColumnSearch}
          />
        );
      };
      col.filterIcon = () => <SearchOutlined />;
    }

    if (column.type === undefined) {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });

        if (row.answers) {
          return <span className="notranslate">{data?.v}</span>;
        }

        return (
          <span
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={async () => handleCellClick({ column, row })}
            className="notranslate"
          >
            {data?.v}
          </span>
        );
      };
    } else if (column.type === "operations") {
      col.render = (row: any) => {
        const operations = binder.getCell({ column, row });

        return operations.map((operation: any, index: number) => (
          <span key={index}>
            {index > 0 && <Divider type="vertical" />}
            <Button
              key={operation.f_id}
              onClick={() =>
                handleCellClick({ column, row, operation: operation.op })
              } /*  handleOperationButtonClick(d, row) */
            >
              {operation.v}
            </Button>
          </span>
        ));
      };
      col.width = "35%";
      col.align = "center";
    } else if (column.type === "status") {
      col.filterDropdown = (props: any) => {
        return (
          <ColumnHeaderStatus
            {...props}
            column={column}
            gizmo={gizmo}
            setLoading={setLoading}
            setColumns={setColumns}
            setRows={setRows}
            setPagination={setPagination}
          />
        );
      };
      col.filterIcon = () => <FilterOutlined />;

      col.render = (row: any) => {
        const status = column?.status?.find((s) => s.f_id === row.status);
        return (
          <Tooltip title={status?.title}>
            <S.Circle color={status?.color}>◉ </S.Circle>
          </Tooltip>
        );
      };
    } else if (column.type === "user") {
      col.render = (row: any) => {
        const data = binder.getCell({ column, row });
        return <span className="notranslate">{data?.v}</span>;
      };
    }

    return col;
  };

  const handleIndexTypeClick = (type: string) => {
    binder.setIndexType(type);
    setIndexType(type);
  };

  return (
    <S.Index id={`index-${config.fid}`} columns={gizmo.getColumns()}>
      <h2>{label}</h2>
      {/* {config.ops?.index?.rowType === "form.registers" && (
        <Search binder={binder} onSearchResult={handleSearchResult} />
      )} */}
      {config.ops?.index?.rowType === "form.registers" &&
        gizmo.getParams().fid && (
          <>
            <Divider type="vertical" />
            <Button onClick={handleCreateButtonClick}>Crear</Button>
          </>
        )}
      {/* {config.ops?.index?.rowType === "form.registers" && */}
      {false &&
        gizmo.getParams().fid && (
          <>
            <Divider type="vertical" />
            <Button onClick={handleExportButtonClick} disabled={exportDisabled}>
              Exportar
            </Button>
          </>
        )}

      {/* {index?.types?.map && ( */}
      {false && (
        <>
          <Divider type="vertical" />
          <Button
            icon={<TableOutlined />}
            onClick={() => handleIndexTypeClick("table")}
          />
          <Divider type="vertical" />
          <Button
            icon={<EnvironmentOutlined />}
            onClick={() => handleIndexTypeClick("map")}
          />
        </>
      )}

      {columns && rows && indexType === "table" && (
        <Table
          columns={columns?.map(renderColumn)}
          dataSource={rows?.map((row) => row)}
          loading={loading}
          pagination={{
            pageSize: pagination.pageSize,
            total: pagination.total,
            current: pagination.page,
            // showSizeChanger: true,
          }}
          onChange={handlePageChange}
          rowKey="id"
        />
      )}
      {columns && rows && indexType === "map" && (
        // <MapIndex
        //   gizmo={gizmo}
        //   rows={rows}
        //   columns={columns}
        //   pagination={pagination}
        // />
        <Map gizmo={gizmo} />
      )}

      {modalOpen && (
        <PrivacyModal gizmo={gizmo} open={modalOpen} setOpen={setModalOpen} />
      )}
    </S.Index>
  );
};

interface IHandleCellClick {
  column: IIndexColumn;
  row: any;
  action?: string;
  operation?: any;
}

export default Index;
