import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Text.styles";
import { GizmoDisplay } from "../../utils";

type TextProps = {
  gizmo: Gizmo;
};

const Text: FC<TextProps> = ({ gizmo }) => {
  const { features } = useGizmo({ gizmo });

  return (
    <GizmoDisplay features={features}>
      <S.TextDiv
        id={`text-${gizmo.getConfig().fid}`}
        columns={gizmo.getColumns()}
      >
        {features.label && (
          <div dangerouslySetInnerHTML={{ __html: features.label }} />
        )}
      </S.TextDiv>
    </GizmoDisplay>
  );
};

export default Text;
