import { FC, useEffect, useState } from "react";
import { Gizmo, IGizmo, useGizmo } from "flowy-3-core";
import { Popconfirm } from "antd";
import PrivacyModal from "./PrivacyModal";
import * as S from "./Button.styles";

type ButtonProps = {
  gizmo: Gizmo;
};

interface IModalConfig {
  title: string;
  policy: string;
}

const Button: FC<ButtonProps> = ({ gizmo }) => {
  const { config, features, binder } = useGizmo({ gizmo });
  const [modalConfig, setModalConfig] = useState<IModalConfig | undefined>();

  useEffect(() => {
    if (binder) {
      binder.data.subscribe((data: any) => {
        if (data.file && data.filename) {
          const downloadLink = document.createElement("a");
          downloadLink.href = URL.createObjectURL(data.file);
          downloadLink.download = data.filename;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      });
    }
  }, [binder]);

  const handleButtonClick = async () => {
    const config = binder.getConfig();
    let displayModal = false;
    const rootGizmoConfig: IGizmo | undefined = gizmo.getCallbacks().getInfoCB({
      type: "rootGizmoConfig",
    });
    if (rootGizmoConfig?.ops?.form?.privacyPolicy?.onCreate) {
      displayModal = true;
    }

    console.log("ddddddddisplayModal: ", displayModal);

    if (
      displayModal &&
      rootGizmoConfig?.ops?.form?.privacyPolicy?.title &&
      rootGizmoConfig.ops.form.privacyPolicy.policy
    ) {
      setModalConfig({
        title: rootGizmoConfig.ops.form.privacyPolicy.title,
        policy: rootGizmoConfig.ops.form.privacyPolicy.policy,
      });
    } else {
      if (binder && config.ops?.button?.operation) {
        await binder.dispatchOperation({
          operation: config.ops.button.operation,
        });
      }
    }
  };

  const handleModalOk = async () => {
    if (binder && config.ops?.button?.operation) {
      setModalConfig(undefined);
      await binder.dispatchOperation({
        operation: config.ops.button.operation,
      });
    }
  };
  const handleModalCancel = () => {
    setModalConfig(undefined);
  };

  return (
    <>
      {config.ops?.button?.confirmation?.title ? (
        <Popconfirm
          title={config.ops?.button?.confirmation?.title}
          description={config.ops?.button?.confirmation?.description}
          onConfirm={handleButtonClick}
        >
          <S.Button
            id={`button-${gizmo.getConfig().fid}`}
            loading={features.processing}
            type="primary"
          >
            {features.label}
          </S.Button>
        </Popconfirm>
      ) : (
        <S.Button
          id={`button-${gizmo.getConfig().fid}`}
          //columns={gizmo.getColumns()}
          loading={features.processing}
          onClick={handleButtonClick}
          type="primary"
        >
          {features.label}
        </S.Button>
      )}
      {modalConfig && (
        <PrivacyModal
          open={true}
          title={modalConfig.title}
          policy={modalConfig.policy}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
        />
      )}
    </>
  );
};

export default Button;
