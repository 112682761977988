import { Row } from "antd";
import { comemosTodos } from "../src/assets/images";

const Closed = () => {
  return (
    // <div className="closed">
    // <h1>Sorry, we are closed</h1>
    // </div>
    <Row justify={"center"}>
      <img
        src={comemosTodos}
        style={{
          maxWidth: "100%",
        }}
      />
      <h3>
        El periodo de inscripción ha concluido, te invitamos a estar pendiente
        de la publicación de resultados en las redes sociales de la Secretaría
        de Bienestar de Quintana Roo.
      </h3>
      {/* <h3>
        El periodo de inscripción es el 31 de enero del 2024, te invitamos a
        estar pendiente de las redes sociales de la Secretaría de Bienestar de
        Quintana Roo y en{" "}
        <a href="https://qroo.gob.mx/sebien/comemostodasytodos">
          qroo.gob.mx/sebien/comemostodasytodos
        </a>
        .
      </h3> */}
    </Row>
  );
};

export default Closed;
